<template>
  <div class="new-entity" v-if="canAccess">
    <page-header
      title="Ajouter un contact"
      icon="fa fa-address-book"
    ></page-header>
    <b-row>
      <b-col cols="12">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div class="add-entity-form" v-if="!isLoading(loadingName)">
          <b-row>
            <b-col>
              <div class="add-entity-item">
                <individual-identity
                  :is-family="false"
                  :entity="entity"
                  :member="firstMember"
                  :add-mode="true"
                  :force-save="forceIndividualSave"
                  :upper-case-name="upperCaseName"
                  @is-valid-changed="individualIsValid = $event.isValid"
                  @last-name-changed="forceEntityName = $event.individual.lastName"
                  @change="onIndividualSaved"
                  @failed="onIndividualFailed"
                >
                </individual-identity>
              </div>
            </b-col>
            <b-col>
              <div class="add-entity-item">
                <entity-detail
                  :entity="entity"
                  :show-all="true"
                  :add-mode="true"
                  :initial-edit-mode="true"
                  :is-family="false"
                  :force-entity-name="forceEntityName"
                  :force-save="forceEntitySave"
                  @uppercase="onUpperCaseChanged($event)"
                  @is-valid-changed="entityIsValid = $event.isValid"
                  @saved="onEntitySaved"
                >
                </entity-detail>
              </div>
            </b-col>
          </b-row>
          <b-row class="buttons-bar">
            <b-col class="text-right">
              <a
                href
                @click.prevent="onSave()"
                class="btn btn-primary"
                :class="{ disabled: !isValid, }"
              >
                Enregistrer
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import router from '@/router'
import store from '@/store'
import LoadingGif from '@/components/Controls/LoadingGif'
import EntityDetail from '@/components/Entity/EntityDetail'
import IndividualIdentity from '@/components/Individual/IndividualIdentity'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import { makeFieldsGroup } from '@/types/fields'
import { makeEntityRole, makeGender, makeEntity, makeEntityMembership } from '@/types/people'
import { BackendApi } from '@/utils/http'

export default {
  name: 'add-new-entity',
  components: { LoadingGif, EntityDetail, IndividualIdentity, PageHeader, },
  mixins: [BackendMixin],
  data() {
    return {
      entity: makeEntity(),
      firstMember: makeEntityMembership({ email_contact: true, }),
      loadingName: 'add-new-entity',
      otherMembers: [],
      forceEntityName: '',
      entityIsValid: false,
      individualIsValid: false,
      forceEntitySave: false,
      forceIndividualSave: false,
      upperCaseName: false,
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    canAccess() {
      return this.hasPerm('people.add_contact')
    },
    isValid() {
      return this.individualIsValid && this.entityIsValid
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['setGenders', 'setEntityRoles', 'setFieldGroups', 'startLoading', 'endLoading']),
    onSave() {
      if (this.entity.id === 0) {
        this.forceEntitySave = true
      } else {
        this.forceIndividualSave = true
      }
    },
    onEntitySaved(event) {
      this.forceEntitySave = false
      this.entity = event.entity
      this.forceIndividualSave = true
    },
    onIndividualSaved(event) {
      this.forceIndividualSave = false
      this.addSuccess('La structure a été créé')
      router.push(
        {
          name: 'entities-detail',
          params: { entityId: this.entity.id, },
          query: { individual: event.id, },
        }
      )
      this.entity = makeEntity()
      this.firstMember = makeEntityMembership({ email_contact: true, })
    },
    onIndividualFailed() {
      this.forceIndividualSave = false
    },
    onUpperCaseChanged(event) {
      this.upperCaseName = event.upperCaseName
    },
    async loadData() {
      if (this.isAuthenticated && this.canAccess) {
        this.startLoading(this.loadingName)
        try {
          let backendApi, resp

          backendApi = new BackendApi('get', '/api/people/genders/')
          resp = await backendApi.callApi()
          this.setGenders(resp.data.map(elt => makeGender(elt)))

          backendApi = new BackendApi('get', '/api/people/roles/')
          resp = await backendApi.callApi()
          await this.setEntityRoles(resp.data.map(elt => makeEntityRole(elt)))

          if (this.hasPerm('fields.view_fieldsgroup')) {
            backendApi = new BackendApi('get', '/api/fields/groups/')
            resp = await backendApi.callApi()
            await this.setFieldGroups(resp.data.map(elt => makeFieldsGroup(elt)))
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
  },
}
</script>

<style lang="less">
</style>
